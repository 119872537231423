/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
  pointer-events: none;
}

.zoom-in {
  animation: zoom-in 0.6s ease;
}

@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(30, 138, 132); */
}

.bg-green-100 {
  background-color: #d1fae5 !important;
}

.bg-opacity-60 {
  --tw-bg-opacity: 0.6 !important;
}

.bg-opacity-0 {
  --tw-bg-opacity: 0 !important;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(200, 200, 200);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(83, 171, 243);
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece:hover {
  background: rgb(161, 213, 255);
  border-radius: 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  display: inline;
  margin: 0 5px;
}

.pagination li a {
  cursor: pointer;
  padding: 8px 16px;
  /* border: 1px solid #ddd; */
  /* color: #007bff; */
}

.pagination li a.active {
  /* background-color: #007bff; */
  color: white;
}

.pagination li a.disabled {
  color: #ddd;
}